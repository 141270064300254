import React from 'react';
import { graphql, Link, PageProps } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import classnames from 'classnames';
import Layout from '../components/Layout';
import NotFoundPage from '../pages/404';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { WorldsFairHead } from '../components/Head';

type introductionIntroContentSection = {
    id: string;
    title: string;
    content: string;
};

const Introduction = ({ data: { introduction, gallery } }: PageProps<Queries.IntroductionTemplateQuery>) => {
    if (!introduction) {
        return <NotFoundPage />
    }

    const {
        tghpworldsfairIntroGallery: introGallery,
        tghpworldsfairIntroTitle: title,
        tghpworldsfairIntroSection1Title: section1Title,
        tghpworldsfairIntroSection1Content: section1Content,
        tghpworldsfairIntroSection2Title: section2Title,
        tghpworldsfairIntroSection2Content: section2Content,
        tghpworldsfairIntroTimelineTitle: introTimelineTitle,
        tghpworldsfairIntroTimelineContent: introTimelineContent,
        tghpworldsfairTimeline: timeline,
    } = introduction;

    console.log(introduction);

    return (
        <Layout location="introduction" showSignup={true} seoData={introduction.seo}>
            <div className="introduction-intro">
                <h1 className="introduction-intro__title">{title}</h1>
                {introGallery?.nodes &&
                    <div className="introduction-intro__gallery">
                        <CarouselProvider
                            naturalSlideWidth={322}
                            naturalSlideHeight={430}
                            totalSlides={introGallery.nodes.length}
                            infinite={true}
                        >
                            {gallery?.slug &&
                                <Link to={`/${gallery.slug}`} className="introduction-intro__gallery-link">
                                    Go To Gallery
                                </Link>
                            }
                            <ButtonBack>
                                <span>Back</span>
                            </ButtonBack>
                            <ButtonNext>
                                <span>Next</span>
                            </ButtonNext>
                            <Slider>
                                {introGallery.nodes.map((image, i) => {
                                    if (!image?.gatsbyImage) {
                                        return '';
                                    }

                                    const {
                                        gatsbyImage
                                    } = image;

                                    return (
                                        <Slide index={i} key={i}>
                                            <GatsbyImage image={image.gatsbyImage} alt={`Gallery preview image ${i}`} />
                                        </Slide>
                                    );
                                })}
                            </Slider>
                        </CarouselProvider>
                    </div>
                }
                {([
                    { id: 'about-wfc', title: section1Title, content: section1Content },
                    { id: 'about-twf', title: section2Title, content: section2Content },
                ] as introductionIntroContentSection[]).map(({ id, title, content }, index) =>
                    <div
                        className={classnames([
                            'introduction-intro__content-section',
                            `introduction-intro__content-section--${id}`
                        ])}
                         key={id}
                    >
                        <div className="introduction-intro__content-section-title">{title}</div>
                        <div className="introduction-intro__content-section-body">{content}</div>
                    </div>
                )}
            </div>
            <div className="introduction-timeline">
                {introTimelineTitle && introTimelineContent &&
                    <>
                        <div className="introduction-timeline__title">{introTimelineTitle}</div>
                        <div className="introduction-timeline__content">{introTimelineContent}</div>
                    </>
                }
                <div className="introduction-timeline__items">
                    {timeline && timeline.map((timelineItem, index) => {
                        if (!timelineItem) {
                            return '';
                        }

                        const { title, description, location, year, image } = timelineItem;

                        return (
                            <div className="introduction-timeline__item" key={`${year}${title}`}>
                                <div className="introduction-timeline__item-year">{year}</div>
                                {image?.nodes && image?.nodes[0]?.gatsbyImage &&
                                    <div className="introduction-timeline__item-image">
                                        <GatsbyImage
                                            image={image?.nodes[0].gatsbyImage}
                                            alt={title || ''}
                                            loading={index > 1 ? 'lazy' : 'eager'}
                                        />
                                    </div>
                                }
                                <div className="introduction-timeline__item-title">{title}</div>
                                <div className="introduction-timeline__item-location">{location}</div>
                                <div className="introduction-timeline__item-description">{description}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Layout>
    );
}

export default Introduction;

export const query = graphql`
    query IntroductionTemplate ($id: Int!, $galleryTemplateName: String!) {
        introduction: wpPage(databaseId: { eq: $id }) {
            tghpworldsfairIntroGallery {
                nodes {
                    gatsbyImage(layout: FULL_WIDTH, width: 520, sizes: "520px")
                }
            }
            tghpworldsfairIntroTitle
            tghpworldsfairIntroSection1Title
            tghpworldsfairIntroSection1Content
            tghpworldsfairIntroSection2Title
            tghpworldsfairIntroSection2Content
            tghpworldsfairIntroTimelineTitle
            tghpworldsfairIntroTimelineContent
            tghpworldsfairTimeline {
                title
                description
                location
                year
                image {
                    nodes {
                        gatsbyImage(layout: FULL_WIDTH, width: 520, sizes: "335px")
                    }
                }
            }
            ...SeoData
        }
        
        gallery: wpPage(template: { templateName: { eq: $galleryTemplateName }}) {
            slug
        }
    }
`

export const Head = () => <WorldsFairHead />