import React from 'react';

type WorldsFairHeadProps = {
    children?: React.ReactNode;
}

export const WorldsFairHead: React.FC<WorldsFairHeadProps> = ({ children }) => {
    return (
        <>
            <link rel="icon" type="image/png" href="/images/favicon.png" />
            {children}
        </>
    );
}